<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Gelenkmarkise / Sunrain <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Sunrain - Die Markise für Sonne und Regen
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:my-10">
          <iframe
            class="w-full"
            width="700"
            height="600"
            src="https://www.youtube.com/embed/hvgRZjMYeas"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-span-full my-10">
          <h3 class="text-2xl pl-5 md:pl-10 md:pb-3" style="color: #033859">
            Die Markise für jedes Wetter
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Genießen Sie Ihre Terrasse ganz unabhängig vom Wetter mit SUNRAIN,
            der Markise für Sonne und Regen. Die patentierte Konstruktion in
            Kombination mit dem absolut wasserdichten Tuch machen es möglich. So
            können Sie Ihr Grillfest oder Ihre Sommerparty wetterunabhängig
            planen
          </p>
        </div>
        <div class="py-6 col-span-full">
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>
          <div
            class="overflow-x-scroll flex text-gray-600 mt-1 border shadow-sm gap-1 cursor-pointer w-full max-h-40"
          >
            <img
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :src="image.src"
              :alt="image.alt"
              :style="{ backgroundImage: 'url(' + image + ')' }"
            />
          </div>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Details">
            <p class="text-lg">
              Die patentierte SUNRAIN Markise gewährleistet jederzeitein
              straffes Tuch und einen gezielten Wasserablauf. Ausgestattet mit
              einem wasserdichten Hochleistungstuch, hält sie auch aus-dauerndem
              Regen stand. Zwei Quer-pfetten verhindern, dass sich Wasser auf
              dem Tuch sammelt. Bereits ab einer geringen Markisenneigung von 5°
              ist der Wasserablauf gesichert (ab 7,5° bei gekuppelten Anlagen).
              <br /><br />Deshalb bleibt auch bei niedriger Anbringung immer
              genügend Raum unter der Markise. Alle belastbaren Teile sind aus
              speziell gehärtetem Aluminium gefertigt. Dies sorgt auch bei
              starker Beanspruchung für Sicherheit. Die stabile Konstruktion
              ermöglicht einen Ausfall bis zu 4 m.
            </p>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 650 cm <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 40 cm <br />
              <span class="font-bold">Winkelung:</span> <br />
              5° - 45° <br />
              <span class="font-bold">Ausstattung:</span> <br />
              Safety Motion <br />
              WPS <br />
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    Tab,
    Tabs,
    CoolLightBox,
  },
  data: function () {
    return {
      items: [
        {
          src: require("../assets/img/leiner/sunrain/header-sunrain01.webp"),
          alt: "gelenkmarkise leiner sunrain",
        },
        {
          src: require("../assets/img/leiner/sunrain/header-sunrain03.webp"),
          alt: "gelemnkmarkise sunrain garten ansicht",
        },
        {
          src: require("../assets/img/leiner/sunrain/header-sunrain04.webp"),
          alt: "gelenkmarkise hausansicht",
        },
        {
          src: require("../assets/img/leiner/sunrain/header-sunrain05.webp"),
          alt: "gelenkmarkise sunrain nachts",
        },
        {
          src: require("../assets/img/leiner/sunrain/sunrain.webp"),
          alt: "gelenkmarkise sunrain offen",
        },
      ],
      index: null,
    };
  },
};
</script>
<style>
.image {
  min-width: 20rem;
}
</style>
